<template>
  <div class="container-fluid">
    <Header />
    <div class="mx-auto mt-1 content-outer-container">
      <div class="wrap-UserLevel">
        <div class="mx-auto content-inner-container border-header">
          <div class="col-12 title-header pl-4">
            <div @click="showModal()" class="back-btn mr-3">
              <img src="@/assets/images/userlevel/arrow_back.svg" alt="" />
            </div>
            {{ lbl['user-level-create-member-level'] }}
          </div>
        </div>
        <div class="user-body col-12">
          <div class="body-container">
            <StepProgress
              :step="2"
              :header="progressHeader"
              :desc="progressDesc"
            ></StepProgress>
            <div class="body-main">
              <div class="main-content">
                <ul class="table-header">
                  <li class="header-name">
                    {{ lbl['user-level-table-name'] }}
                  </li>
                  <li class="header-badge">
                    {{ lbl['user-level-table-badge'] }}
                  </li>
                  <li class="header-point">
                    {{
                      dataUserLevel.levelBy == 1
                        ? lbl['user-level-table-point-condition']
                        : lbl['user-level-table-spending-condition']
                    }}
                  </li>
                  <li class="header-remove"></li>
                </ul>
                <ul
                  class="table-row"
                  v-for="(item, index) in data"
                  :key="index"
                >
                  <li class="column-name">
                    <a-input
                      type="text"
                      v-model="item.name"
                      @change="changeName(index)"
                    />
                  </li>
                  <li class="column-badge">
                    <img :src="`${item.url}`" alt="" />
                    <span class="ml-2" @click="uploadImage(index)">{{
                      lbl['user-level-upload-image']
                    }}</span>
                  </li>
                  <li class="column-point">
                    <a-input
                      class="start default"
                      type="text"
                      disabled
                      v-model="item.start"
                    />
                    <a-form-item
                      class="mb-0"
                      :class="{ 'has-error': item.error }"
                    >
                      <a-input
                        class="end"
                        type="text"
                        v-model="item.end"
                        @copy.prevent
                        @paste.prevent
                        @click.right.prevent
                        @keypress="onlyNumber"
                        @blur="onBlurChangeEndPoint(index)"
                        @change="changeEndPoint(index)"
                      />
                    </a-form-item>
                  </li>
                  <li class="column-remove">
                    <button
                      :disabled="
                        index != data.length - 1 ||
                        index == 0 ||
                        index <= memberLevelTotalIndex
                      "
                      @click="removeLevel(index)"
                      class="remove-btn"
                      :class="{
                        'remove-disabled':
                          index != data.length - 1 ||
                          index == 0 ||
                          index <= memberLevelTotalIndex,
                      }"
                    >
                      <img
                        src="@/assets/images/userlevel/userlevel_Trash.svg"
                        alt=""
                      />
                      {{ lbl['user-level-button-remove'] }}
                    </button>
                  </li>
                </ul>
              </div>
              <div class="add-container">
                <button
                  v-if="isAddLevel"
                  :disabled="isDisable"
                  class="add-btn"
                  @click="addLevel()"
                >
                  <img src="@/assets/images/userlevel/add_blue.svg" alt="" />
                  {{ lbl['user-level-button-add-level'] }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="user-footer col-12 between mb-1 pb-1">
          <div @click="back()" class="previous-btn">
            <img
              class="mr-2"
              src="@/assets/images/userlevel/arrow_back.svg"
              alt=""
            />
            {{ lbl['user-level-previous-button-step-1'] }}
          </div>
          <b-button variant="warning" class="userlevel-btn" @click="next()">
            {{ lbl['user-level-button-next'] }}
            <img src="@/assets/images/userlevel/arrow_forward.svg" alt="" />
          </b-button>
        </div>
        <div class="warning-create col-12">
          <span>{{ lbl['user-level-warning-create'] }}</span>
        </div>
      </div>
    </div>
    <ModalCancle />
    <ModalUploadImage @clicked="onClickChild" />
  </div>
</template>
<script>
import _ from 'lodash'
// mixin
import Account from '@/helper/AccountHelper.js'
import Mixin from '@/mixin/Mixin'
// components
import Header from '@/components/Layout/Header'
import StepProgress from '@/module/UserProfile/components/StepProgress.vue'
import ModalCancle from '@/module/UserProfile/modal/CancleModal.vue'
import ModalUploadImage from '@/module/UserProfile/modal/UploadImageModal.vue'
import BzbsUserProfile from '@/core/UserProfile/service/BzbsUserProfile'
import Util from '@/helper/Utility.js'
export default {
  name: 'StepTwo',
  components: {
    Header,
    StepProgress,
    ModalCancle,
    ModalUploadImage,
  },
  mixins: [Mixin],
  data: function () {
    return {
      currenttitle: null,
      current: null,
      parent: null,
      progressHeader: '',
      progressDesc: '',
      badgeList: [],
      data: [
        {
          name: 'Standard',
          url: null,
          start: 0,
          end: 199,
          error: false,
        },
        {
          name: 'Bronze',
          url: null,
          start: 200,
          end: 499,
          error: false,
        },
        {
          name: 'Gold',
          url: null,
          start: 500,
          end: 1000,
          error: false,
        },
      ],
      currentUpload: 0,
      isAddLevel: true,

      dataUserLevel: {},
      isDisable: false,
      memberLevelTotalIndex: 0,
      memberLevelActiveList: [],
    }
  },
  created() {
    this.handleFooter(true)
    this.handleLoading(false)
    Account.bzbsAnalyticTracking(
      'member_level_step2',
      'member_level',
      'view_member_level_step2',
      'on view',
    )
    this.init()
    if (this.data.length == 5) {
      this.isAddLevel = false
    }
  },
  methods: {
    init() {
      this.currenttitle = this.lbl['user-level-member-level']
      this.current = this.lbl['user-level-create-member-level']
      this.progressHeader = this.lbl['user-level-member-level-details']
      this.progressDesc = this.lbl['user-level-member-level-condition']
      this.dataUserLevel = JSON.parse(localStorage.getItem('dataUserLevel'))
      if (this.dataUserLevel != undefined) {
        if (this.dataUserLevel.memberLevel != undefined) {
          this.data = this.dataUserLevel.memberLevel
          this.checkData()
          if (this.dataUserLevel.isActive) {
            this.memberLevelTotalIndex =
              JSON.parse(localStorage.getItem('dataUserLevel')).memberLevel
                .length - 1
            this.memberLevelActiveList = JSON.parse(
              localStorage.getItem('dataUserLevel'),
            ).memberLevel
          } else {
            this.memberLevelTotalIndex = -1
          }
          if (this.data.length <= 0) {
            setTimeout(() => this.getbadgeList(), 100)
            setTimeout(() => this.checkData(), 200)
          } else {
            this.checkDisable(null)
          }
        } else {
          this.memberLevelTotalIndex = -1
          setTimeout(() => this.getbadgeList(), 100)
          setTimeout(() => this.checkData(), 200)
        }
      } else {
        this.$router.push({
          name: 'CreateUserLevel',
        })
      }
    },
    getbadgeList() {
      this.handleLoading(true)
      return new Promise(resolve => {
        BzbsUserProfile.getLevelIcon()
          .then(res => {
            if (res.data != null) {
              var result = _.map(res.data, (e, i) => {
                return { key: i, url: e }
              })
              this.badgeList = result
              this.genbadgeList(result)
            }
            this.handleLoading(false)
            resolve(res.data)
          })
          .catch(error => {
            this.handleLoading(false)
            console.log('getLevelIcon error', error)
            resolve(error)
          })
      })
    },
    genbadgeList(result) {
      if (result.length >= 3) {
        this.data[0].url = result[0].url
        this.data[1].url = result[1].url
        this.data[2].url = result[2].url
      } else {
        if (result.length > 0) {
          this.data[0].url = result[0].url
          this.data[1].url = result[0].url
          this.data[2].url = result[0].url
        }
      }
    },
    showModal() {
      Account.bzbsAnalyticTracking(
        'member_level_step2',
        'member_level',
        'click_member_level_step2_exit',
        'on click',
      )
      this.$bvModal.show('modalCancle')
    },
    uploadImage(index) {
      this.currentUpload = index
      this.$bvModal.show('modalUploadImage')
    },
    onBlurChangeEndPoint(index) {
      if (this.dataUserLevel.isActive) {
        if (index <= this.memberLevelTotalIndex) {
          if (
            parseInt(this.data[index].end) <
            parseInt(this.memberLevelActiveList[index].end)
          ) {
            this.data[index].end = this.memberLevelActiveList[index].end
            this.changeEndPoint(index)
          } else {
            for (let i = index; i < this.data.length; i++) {
              if (!this.checkDisable(index)) {
                if (i >= index) {
                  if (index < this.data.length && i != this.data.length - 1) {
                    if (i == index) {
                      this.data[index + 1].start =
                        parseInt(this.data[index].end) + 1
                      if (
                        this.data[index + 1].start >= this.data[index + 1].end
                      ) {
                        this.data[index + 1].end =
                          parseInt(this.data[index + 1].start) + 1
                      }
                    } else {
                      this.data[i + 1].start = parseInt(this.data[i].end) + 1
                      if (this.data[i + 1].start >= this.data[i + 1].end) {
                        this.data[i + 1].end =
                          parseInt(this.data[i + 1].start) + 1
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    changeEndPoint(index) {
      for (let i = index; i < this.data.length; i++) {
        if (!this.checkDisable(index)) {
          if (i >= index) {
            if (index < this.data.length && i != this.data.length - 1) {
              if (i == index) {
                this.data[index + 1].start = parseInt(this.data[index].end) + 1
                if (this.data[index + 1].start >= this.data[index + 1].end) {
                  this.data[index + 1].end =
                    parseInt(this.data[index + 1].start) + 1
                }
              } else {
                this.data[i + 1].start = parseInt(this.data[i].end) + 1
                if (this.data[i + 1].start >= this.data[i + 1].end) {
                  this.data[i + 1].end = parseInt(this.data[i + 1].start) + 1
                }
              }
            }
          }
        }
      }
    },
    checkDisable(index) {
      if (index != null) {
        if (
          this.data[index].end == null ||
          Util.isEmptyStr(this.data[index].end)
        ) {
          this.isDisable = true
          return true
        } else {
          this.isDisable = false
          return false
        }
      } else {
        if (
          this.data[this.data.length - 1].end == null ||
          Util.isEmptyStr(this.data[this.data.length - 1].end)
        ) {
          this.isDisable = true
          return true
        } else {
          this.isDisable = false
          return false
        }
      }
    },
    addLevel() {
      let url = null
      if (this.badgeList.length > 0) {
        url = this.badgeList[0].url
      }
      this.data.push({
        name: 'Standard',
        url: url,
        start: parseInt(this.data[this.data.length - 1].end) + 1,
        end: parseInt(this.data[this.data.length - 1].end) + 2,
        error: false,
      })
      this.checkData()
    },
    removeLevel(index) {
      if (this.dataUserLevel.isActive) {
        if (index <= this.memberLevelTotalIndex) {
          console.log('cannot remove')
          this.checkData()
        } else {
          if (index !== 0) {
            this.data.splice(index, 1)
          }
          this.checkData()
        }
      } else {
        if (index !== 0) {
          this.data.splice(index, 1)
        }
        this.checkData()
      }
    },
    changeName(index) {
      if (this.data.length > 0) {
        this.data[index].detail = null
      }
    },
    checkData() {
      if (this.data.length >= 5) {
        this.isAddLevel = false
      } else {
        this.isAddLevel = true
      }
      this.checkDisable(null)
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault()
      }
    },
    onClickChild(value) {
      this.data[this.currentUpload].url = value
    },
    checkNotNull() {
      let num_null = 0
      for (var i = 1; i <= this.data.length; i++) {
        if (i != this.data.length) {
          if (this.data[i - 1].end == null || this.data[i - 1].end == '') {
            this.data[i - 1].error = true
            num_null++
          } else {
            this.data[i - 1].error = false
          }
        } else {
          if (num_null > 0) {
            return false
          } else {
            return true
          }
        }
      }
    },
    checkValue() {
      let num_value = 0
      for (var i = 1; i <= this.data.length; i++) {
        if (i != this.data.length) {
          if (this.data[i - 1].end <= this.data[i - 1].start) {
            this.data[i - 1].error = true
            num_value++
          } else {
            this.data[i - 1].error = false
          }
        } else {
          if (
            this.data[i - 1].end <= this.data[i - 1].start &&
            this.data[i - 1].end != '' &&
            this.data[i - 1].end != null
          ) {
            this.data[i - 1].error = true
            return false
          } else {
            if (num_value > 0) {
              return false
            } else {
              return true
            }
          }
        }
      }
    },
    next() {
      Account.bzbsAnalyticTracking(
        'member_level_step2',
        'member_level',
        'click_member_level_step2_next',
        'on click',
      )
      if (this.checkNotNull() && this.checkValue()) {
        console.log('pass check not null')
        if (this.dataUserLevel.isEdit != undefined) {
          if (this.dataUserLevel.isEdit == true) {
            this.dataUserLevel.isUpdate = true
          }
        }
        this.dataUserLevel.memberLevel = this.data
        localStorage.setItem(
          'dataUserLevel',
          JSON.stringify(this.dataUserLevel),
        )
        this.$router.replace({
          name: 'StepThree',
        })
      }
    },
    back() {
      Account.bzbsAnalyticTracking(
        'member_level_step2',
        'member_level',
        'click_member_level_step2_back',
        'on click',
      )
      if (this.dataUserLevel.isEdit != undefined) {
        if (this.dataUserLevel.isEdit == true) {
          this.dataUserLevel.isUpdate = true
        }
      }
      this.dataUserLevel.memberLevel = this.data
      localStorage.setItem('dataUserLevel', JSON.stringify(this.dataUserLevel))
      this.$router.replace({
        name: 'StepOne',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/module/UserProfile/UI/BaseStyle.scss';
ul,
li {
  padding: 0;
  margin: 0;
}
.user-body {
  padding-bottom: 40px;
  width: 100%;
  .body-container {
    width: 100%;
    .body-main {
      margin: 0 25px;
      .main-content {
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
      }
    }
  }
}
</style>
