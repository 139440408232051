<template>
  <b-modal
    id="modalUploadImage"
    modal-class="modal-list"
    :modalClass="[currentLocale]"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <div class="modal-base">
      <div class="modal-header">
        <h3>{{ lbl['user-level-upload-image'] }}</h3>
      </div>
      <div class="modal-desc">
        <b-alert
          :show="dismissCountDown"
          dismissible
          variant="danger"
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          {{ lbl['user-level-message-error-upload-type'] }}
        </b-alert>
        <p>{{ lbl['user-level-select-form-badge-template'] }}</p>
        <ul class="badge-list">
          <li
            v-for="item in badgeList"
            :key="item.key"
            class="badge-item"
            :class="{ selected: item.url === selectedBadge }"
            @click="selected(item.url)"
          >
            <img :src="`${item.url}`" alt="" />
          </li>
        </ul>
      </div>
      <div class="spacing">{{ lbl['user-level-or'] }}</div>
      <div class="modal-btn">
        <label class="btn-upload" for="upload-photo">
          <img src="@/assets/images/upload.svg" alt="" />
          <span>{{ lbl['user-level-upload-artwork'] }}</span>
        </label>
        <input
          type="file"
          class="upload-file"
          name="photo"
          id="upload-photo"
          accept="image/x-png"
          @input="uploadImage($event)"
        />
        <div class="btn-condition">
          <p>{{ lbl['user-level-file-format'] }}</p>
          <p>{{ lbl['user-level-file-size'] }}</p>
          <p>{{ lbl['user-level-image-size'] }}</p>
        </div>
      </div>
    </div>
    <div class="modal-base-footer">
      <button class="btn-close" @click="$bvModal.hide('modalUploadImage')">
        {{ lbl['user-level-button-cancel'] }}
      </button>
      <button
        class="btn-submit"
        :disabled="selectedBadge == null || selectedBadge == ''"
        @click="onClickButton()"
      >
        {{ lbl['user-level-button-save'] }}
      </button>
    </div>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import Mixin from '@/mixin/Mixin'
import BzbsUserProfile from '@/core/UserProfile/service/BzbsUserProfile'
import fileReader from '@/helper/fileReader'
import Locale from '@/helper/locale.js'

export default {
  name: 'ModalUploadImage',
  mixins: [Mixin],
  data: function () {
    return {
      badgeList: [],
      selectedBadge: '',
      imageUrl: null,
      params: {},
      dismissCountDown: 0,
      currentLocale: Locale.getLocaleShort(),
    }
  },
  created() {
    this.handleLoading(false)
    this.init()
  },
  methods: {
    init() {
      this.getbadgeList()
    },
    getbadgeList() {
      return new Promise(resolve => {
        BzbsUserProfile.getLevelIcon()
          .then(res => {
            if (res.data != null) {
              var result = _.map(res.data, (e, i) => {
                return { key: i, url: e }
              })
              this.badgeList = result
            }
            resolve(res.data)
          })
          .catch(error => {
            console.log('getLevelIcon error', error)
            resolve(error)
          })
      })
    },
    selected(index) {
      this.selectedBadge = index
    },
    onClickButton() {
      this.$emit('clicked', this.selectedBadge)
      this.$bvModal.hide('modalUploadImage')
    },
    uploadImage(event) {
      console.log('event : ', event)
      var uploadFile = event.target.files[0]
      var afterDot = uploadFile.name.substr(0, uploadFile.name.indexOf('.'))
      var nonSpacial = afterDot.replace(/[^a-zA-Z0-9]/g, '')
      var newFileName = nonSpacial.replace(/\s/g, '_')
      var resultFile = new File([uploadFile], newFileName, {
        type: uploadFile.type,
      })
      this.imageUrl = URL.createObjectURL(resultFile)
      if (uploadFile.type == 'image/png') {
        console.log('type : true')
        fileReader.getBaseImage(this.imageUrl).then(data => {
          this.params.imageLevelIcon = fileReader.blobToFile(data)

          return new Promise(resolve => {
            BzbsUserProfile.postUploadLevelIcon(this.params)
              .then(res => {
                if (res.status == 200) {
                  this.getbadgeList()
                }
                resolve(res.data)
              })
              .catch(error => {
                resolve(error)
              })
          })
        })
      } else {
        this.dismissCountDown = 5
      }
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
    },
  },
}
</script>

<style lang="scss" scoped>
$base-orange: #ff9800;

::v-deep .modal-content {
  border-radius: 0;

  .modal-body {
    .modal-base {
      .modal-header {
        padding: 0;
        justify-content: flex-start;
        border-bottom: solid 1px #f5f5f5;
        h3 {
          font-size: 1.25rem;
          margin: 0;
          padding-bottom: 1rem;
        }
      }
      .modal-desc {
        margin-top: 10px;
        padding: 0;
        width: 90%;
        .badge-list {
          display: flex;
          padding: 0;
          margin: 0;
          flex-wrap: wrap;
          .badge-item {
            list-style: none;
            margin: 0 10px 10px 0;
            box-sizing: border-box;
            border: solid 2px transparent;
            padding: 2px;
            img {
              width: 35px;
            }
            &.selected {
              border: solid 2px #2196f3;
            }
          }
        }
      }
      .spacing {
        margin: 8px 0;
        font-weight: 600;
      }
      .modal-btn {
        display: flex;
        margin-bottom: 25px;
        .btn-upload {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px 12px;
          background-color: #fff;
          color: $base-orange;
          border: solid 1px $base-orange;
          border-radius: 4px;
          margin-right: 8px;
          font-size: 13px;
          font-weight: 600;

          img {
            width: 1rem;
            height: 1rem;
            margin-right: 5px;
          }
        }
        .upload-file {
          width: 0;
          height: 0;
        }
        .btn-condition {
          p {
            margin: 0;
            font-size: 10px;
            color: #757575;
            line-height: 0.85rem;
          }
        }
      }
    }
    .modal-base-footer {
      display: flex;
      justify-content: flex-end;
      button {
        padding: 10px 30px;
      }
      .btn-close {
        background-color: #fff;
        color: $base-orange;
        border: solid 1px $base-orange;
        border-radius: 4px;
        margin-right: 8px;
      }
      .btn-submit {
        background-color: $base-orange;
        color: #fff;
        border: none;
        border-radius: 4px;
      }
    }
  }
}
</style>
